import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(25.000000, 20.000000)">
        <path
          d="M0.455994 0.631989H7.99199L21.816 23.672H22.2L21.816 17.048V0.631989H28.248V35H21.432L6.83999 10.664H6.45599L6.83999 17.288V35H0.455994V0.631989Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M81 2H2V73H81V2Z"
      />
    </g>
  </svg>
);

export default IconLoader;
